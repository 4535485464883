<template>
  <v-container fluid>
    <v-card>
      <v-simple-table id="projects-table">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center" id="table-title">Project</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(value, name) in projectContent" :key="name" :id="``">
              <td>{{ capitalizeFirstLetterOfName(name) }}</td>
              <td>{{ value }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import upperFirst from 'lodash/upperFirst';
import formatDate from '@utils/time-formats';

export default {
  name: 'ProjectDataTable',
  props: ['project'],
  data: () => ({
    currentProject: {},
  }),
  methods: {
    capitalizeFirstLetterOfName: (name) => upperFirst(name),
  },
  computed: {
    ...mapGetters(['companyHas']),
    projectContent() {
      const { teams } = this.project;
      const project = {
        date: formatDate(this.project.date, 'YEAR_MONTH_DATE'),
        name: this.project.name,
        description: this.project.description,
        location: this.project.location,
        teams: teams.map((team) => team.name).join(', '),
      };

      project.reviewer = (this.project.first_reviewer) ? this.project.first_reviewer.name : 'No reviewer assigned';

      project['project type'] = (this.project.project_type) ? this.project.project_type : 'This project has not been assigned a project type yet';
      return project;
    },
  },
};
</script>
